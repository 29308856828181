import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import contactimg from "../Images/conta1-re.png";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';
import Helmet from "react-helmet"
const ContactForm = () => {
  useEffect(() => {
    document.title = "Contact Us | RedmineClient - Get in Touch with Our Team";
  }, []);

  const [contact, setContact] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [validation, setValidation] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  function onChange(value) {
    console.log("Captcha value:", value);
    setRecaptchaToken(value);
  }

  const handleChange = (event) => {
    setContact({ ...contact, [event.target.name]: event.target.value });
  };

  const sendEmail = async (e) => {
    e.preventDefault();
  
    // Validate form fields on frontend
    checkValidation();
      // Log contact data and reCAPTCHA token
      console.log("Contact data:", contact);
      console.log("Recaptcha token before sending:", recaptchaToken);
    
    // Check if there are any validation errors
    if (Object.values(validation).some(msg => msg)) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please correct the errors in the form.',
      });
      return;
    }
  
    if (!recaptchaToken) {
      Swal.fire({
        icon: 'error',
        title: 'reCAPTCHA Required',
        text: 'Please complete the reCAPTCHA.',
      });
      return;
    }
  
    // Submit the form if no validation errors
    const res = await fetch("https://api.ifisol.com/send_email.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...contact, recaptchaToken }),
    });
    const data = await res.json();
    console.log(data);
  
    if (data.status === 200) {
      setContact({
        name: "",
        email: "",
        message: "",
      });
      setRecaptchaToken(null); // Reset reCAPTCHA token
      Swal.fire({
        icon: 'success',
        title: 'Email Sent Successfully',
        text: 'Thank you for contacting us!',
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error Sending Email',
        text: data.message || 'An error occurred while sending the email. Please try again later.',
      });
    }
  };
  

  const checkValidation = () => {
    let errors = { ...validation };
    if (!contact.name) {
      errors.name = "Name is required";
    } else {
      errors.name = "";
    }
    if (!contact.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(contact.email)) {
      errors.email = "Invalid email format";
    } else {
      errors.email = "";
    }
    if (!contact.message) {
      errors.message = "Message is required";
    } else {
      errors.message = "";
    }
    setValidation(errors);
  };

  return (
    <>
     <Helmet>
        
     <meta name="description" content="Have questions or need support? Contact RedmineClient for assistance with our project management app. Whether you need help with setup, troubleshooting, or general inquiries, our team is here to provide prompt and effective support. Reach out to us today!"/>
     <meta name="keywords" content="Contact RedmineClient, project management support, customer service, contact us, RedmineClient support, project management app assistance"/>
      </Helmet>
      <section className="section contact" id="contact" aria-label="Contact">
        <div className="contact-container">
          <p className="contact-subtitle">Contact Us</p>
          <h2 className="h2 section-title contact-title">
            Let's Connect Together
          </h2>
          <div className="row">
            <div className="col-md-6 contact">
              <img src={contactimg} alt="" className="w-100 contim" />
            </div>
            <div className="col-md-6 contact">
              <form onSubmit={sendEmail} className="cont-form">
                <div className="mb-3">
                  <label htmlFor="nameInput" className="form-label">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="nameInput"
                    name="name"
                    value={contact.name}
                    placeholder="Enter Your Name"
                    title="Full Name"
                    onChange={handleChange}
                  />
                  {validation.name && (
                    <p style={{ color: "red" }}>{validation.name}</p>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="emailInput" className="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="emailInput"
                    name="email"
                    value={contact.email}
                    placeholder="name@example.com"
                    title="Email"
                    onChange={handleChange}
                  />
                  {validation.email && (
                    <p style={{ color: "red" }}>{validation.email}</p>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="messageInput" className="form-label">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    id="messageInput"
                    rows="3"
                    value={contact.message}
                    name="message"
                    title="Message"
                    onChange={handleChange}
                  ></textarea>
                  {validation.message && (
                    <p style={{ color: "red" }}>{validation.message}</p>
                  )}
                </div>

                <div>
                  <ReCAPTCHA
                    sitekey="6LekazUqAAAAAA7h7Qd7Ag5aWdzBVsNOQBjwFJhV"
                    onChange={onChange}
                  />
                </div>

                <div className="mb-3 btn">
                  <button
                    className="btn btn-secondary"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
