import project from "./Images/project.png";
import tasks from "./Images/tasks.png";
import time from "./Images/timerack.png";
import secure from "./Images/secure.png";
const $data=[
    {
        imgsrc: project,
        h3:"Comprehensive Project Management",
        p:"Efficiently manage and oversee multiple projects with ease. RedmineClient’s robust project management tools allow you to assign tasks, monitor progress, and keep everything on track. Our app is designed to enhance your project oversight, making complex project management straightforward and efficient.",
    },
    {
        imgsrc:tasks,
        h3:"Seamless Task Management",
        p:"Streamline task creation and assignment with our intuitive interface. RedmineClient enables you to add tasks, allocate them to team members, and track their progress effortlessly. Enjoy greater control over task management and ensure that every detail is addressed promptly. ",

    },
    {
        imgsrc:time,
        h3:"Advanced Time Tracking",
        p:"Boost your business’s profitability with precise time tracking. RedmineClient’s time tracking feature allows you to monitor time spent on projects and issues, helping you manage resources more effectively and improve overall project efficiency.",

    },
    {
        imgsrc:secure,
        h3:"Top-Notch Security",
        p:"Protect your data with confidence. RedmineClient offers high-level authentication, secure communication, and advanced data encryption to safeguard your project information. Our reliable data storage solutions ensure your sensitive data remains secure and accessible.",

    },
]
export default $data;