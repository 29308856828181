import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import banner from "../Images/about-banner.webp";
import appstore from "../Images/appstore.png";
import playstore from "../Images/playstore.png";
import logo from "../Images/logo.webp";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet"
const About = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'About Us | RedmineClient - Transforming Project Management';
  }, []);
    return (
        <>
         <Helmet>
         <meta name="description" content="Discover RedmineClient, your ultimate tool 
         for project management. Efficiently track project timelines, assign tasks, manage
          attendance, and implement Scrum methodologies. 
         Learn how our app helps thousands streamline project tracking and enhance team 
         productivity."/>
         <meta name="keywords" content="About RedmineClient, project management, track project timelines, task assigning, attendance management, Scrum methodology, Redmine client app"/>
      </Helmet>
             <section className="section about" id="about" aria-label="about">
        <div className="container">
          <figure className="about-banner">
            <img src={banner} width="1262" height="1357" alt="about banner"
              className="w-100"/>
          </figure>
          <div className="about-content">
            <p className="section-subtitle">About US</p>
            <h2 className="h2 section-title">We Are Trusted By Thousands Of People</h2>
            <p className="section-text">
            At RedmineClient, we're dedicated to transforming how you manage projects.
             As a leading client application for Redmine, we provide an intuitive and powerful
              platform designed to enhance project tracking and team collaboration.
               Our mission is to help you streamline your workflows, boost productivity,
             and ensure timely project completion.
             Discover the Key Benefits of Using RedmineClient:
            </p>
            <ul className="about-list">
              <li className="about-item">
                <div>
                  <h3 className="h3 item-title">Enhanced Project Tracking</h3>
                  <p className="item-text">
                  Track multiple projects effortlessly with our comprehensive features.
                   Assign tasks to team members, monitor progress in real-time, 
                  and stay on top of deadlines—all from one centralized platform.
                  </p>
                </div>
              </li>
              <li className="about-item">
                <div>
                  <h3 className="h3 item-title">Efficient Task Management</h3>
                  <p className="item-text">
                  Create, assign, and manage tasks with ease. Our app simplifies task tracking, 
                  enabling you to keep your projects organized and 
                  ensure that every detail is addressed promptly.
                  </p>
                </div>
              </li>
              <li className="about-item">
<div>
  <h3 className="h3 item-title">Streamlined Workflows</h3>
  <p className="item-text">
  Optimize your team's efficiency with our app's robust tools for planning, 
  coordinating, and executing projects. 
  Experience a more structured approach to project management and watch your 
  productivity soar.
  </p>
</div>
</li>
            </ul>
            <button onClick={()=>navigate("/contact")} className="btn btn-secondary">Contact US</button>
          </div>
        </div>
      </section>
      <section className="section app appsect" aria-label="app" id="app">
        <div className="container">
          <figure className="app-banner">
            <img src={logo} width="449" height="608"  alt="app banner" className="w-100"/>
          </figure>
          <div className="app-content">
            <p className="section-subtitle">Download Our App</p>
            <h2 className="h2 section-title">Available on PlayStore and AppStore</h2>
            <p className="section-text">
            Unlock the full potential of your project management with the RedmineClient app. Whether you’re using Android or iOS, our app is designed to provide seamless project tracking and team coordination on the go.
            </p>
            <ul className="btn-list">
            <li>
                <NavLink to="https://apps.apple.com/nz/app/redmine-client/id1480654349" class="btn-item-link" aria-label="Download from app store">
                  <img src={appstore} width="185" height="69" alt="app store" class="img"/>
                </NavLink>
              </li>
              <li>
                <NavLink to="https://play.google.com/store/apps/details?id=com.ifisol.redmine_client&hl=en" class="btn-item-link" aria-label="Download from play store">
                  <img src={playstore} width="185" height="69" alt="play store" class="play-img img"/>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </section>
        </>
    );
};
export default About;
