import "bootstrap/dist/css/bootstrap.min.css";
import homebanner from "../Images/homebanner.webp";
import playstore from "../Images/playstore.png";
import appstore from "../Images/appstore.png";
import {NavLink} from "react-router-dom";
import Features from "./Features";
import About from "./About";
import ContactForm from "./ContactForm";
import { useEffect } from "react";
import Helmet from "react-helmet"
const Home= () => {
  useEffect(() => {
    document.title = 'Home | RedmineClient - Transforming Project Management';
  }, []);
    return (
        <>
         <Helmet>
         <meta name="description" content="Elevate your project management with RedmineClient App—your all-in-one solution for efficient project tracking, seamless task assignment, precise timeline management, and streamlined Scrum processes. Discover how our powerful app enhances team collaboration, tracks progress, and ensures timely project completion." />
        <meta name="keywords" content="Project Management App, Task Management, Ticketing System, Team Collaboration Tool, Gantt Charts Software, Employee Attendance Tracking, Time Tracking App, Redmine Integration" />
      </Helmet>
      <section class="section hero" aria-label="hero" id="home">
        <div class="container">
          <div class="hero-content">
            <p class="section-subtitle">Streamline Project Management with RedmineClient App</p>
            <h1 class="h1 hero-title">Work more Structured and Organized</h1>
            <p class="section-text">
            Elevate your project management with RedmineClient App—your all-in-one solution for
             efficient project tracking and team coordination. Our app integrates seamlessly 
             with Redmine, offering comprehensive tools to manage tasks,
             track progress, and streamline workflows.Good planning leads to better work.
              Plan work, keep projects on track, 
            and manage teamwork easily with the Redmine Client App.
            </p>
            <ul class="btn-list">
              <li>
                <NavLink to="https://apps.apple.com/nz/app/redmine-client/id1480654349" class="btn-item-link" aria-label="Download from app store">
                  <img src={appstore} width="185" height="69" alt="app store" class="img"/>
                </NavLink>
              </li>
              <li>
                <NavLink to="https://play.google.com/store/apps/details?id=com.ifisol.redmine_client&hl=en" class="btn-item-link" aria-label="Download from play store">
                  <img src={playstore} width="185" height="69" alt="play store" class="play-img img"/>
                </NavLink>
              </li>
            </ul>
          </div>
          <figure class="hero-banner">
            <img src={homebanner} width="769" height="804" alt="hero banner" class="w-100"/>
          </figure>
        </div>
      </section>
      <Features/>
      <About/> 
     <ContactForm/>
        </>
    );
};
export default Home;
