import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Card from "./Card";
import $data from "../Data";
import { useEffect } from "react";
import Helmet from "react-helmet"
const Features=()=>{
    useEffect(() => {
        document.title = 'Features of RedmineClient | Comprehensive Project Management App';
      }, []);
   
    return(
        <>
         <Helmet>
         <meta name="description" content="Explore the powerful features of RedmineClient, including comprehensive project management, seamless task management, advanced time tracking, and top-notch security. Enhance your project efficiency with our all-in-one app."/>
    <meta name="keywords" content="RedmineClient, project management, task management, time tracking, project efficiency, secure project management app."/>
      </Helmet>
      <section class="section features" id="features" aria-label="features">
        <div class="section-container">

          <p class="section-subtitle">Features</p>

          <h2 class="h2 section-title">Explore the Key Features of RedmineClient</h2>
          <ul class="features-list">
        
                    {
                        $data.map((val,index)=>{
                            return <Card
                            key={index}
                                imgsrc={val.imgsrc} 
                                h3={val.h3}
                                p={val.p}
                                 />
                        })
                    }
                    </ul>
                    </div>
                    </section>

                 
                    </> 
    )
}
export default Features;