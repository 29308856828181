import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
const Card = (props) => {
    return (
        <>
                   <li class="features-item">
                    <div class="features-card">
                        <div class="card-icon">
                            <img src={props.imgsrc} alt=""/>
                        </div>

                        <h3 class="h3 card-title">{props.h3}</h3>

                        <p class="card-text">
                           {props.p}
                        </p>
                    </div>
                    </li>
                    
             
        </>
    );
};
export default Card;
