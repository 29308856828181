import React from "react";
import {NavLink} from "react-router-dom";
import { useState } from "react";
import icon from "../Images/icon.png";

function MyNavbar() {
    const[isNavCollapsed, setIsNavCollapsed]=useState(true);
    const handleNavCollapse=()=>setIsNavCollapsed(!isNavCollapsed);
       return (
        <>
        <header className="header" data-header>
    <div className="container">
                    <nav className="navbar fixed-top navbar-expand-lg navbar-light ">
                        <NavLink className="navbar-brand" to="/">
                            <div className="navbar-brand-name">
                        <img src={icon} alt=""/>
                         Redmine Client
                         </div>
                        </NavLink>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded={!isNavCollapsed ? true :false}
                            aria-label="Toggle navigation"
                            onClick={handleNavCollapse} >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                             <div
                            className={`${isNavCollapsed ? 'collapse': ''} navbar-collapse`}
                            id="navbarSupportedContent" >
                            <ul className="navbar-nav  ms-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <NavLink 
                                    className="nav-link active" to="/home">
                                        Home
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink  
                                    className="nav-link active" to="/features">
                                        Features
                                    </NavLink>
                                </li>
                                 <li className="nav-item">
                                    <NavLink 
                                     className="nav-link active" to="/about">
                                        About Us
                                    </NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink 
                                     className="nav-link active" to="/contact">
                                        Contact Us
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
             </header>
             </>
    );
}

export default MyNavbar;
